$(document).ready(function(){
	$(window).load(function(){
        slickSlider();
        slickSlider2();
        hovering();
        what_we_do_slider();
        related_news_slider()
        benefits();
        loadmore();
        lightcas();
		// Global Variables
		var $pageHeight = $(window).height(),
		$pageWidth = $(window).width(),
		$navHeight = $('header.site-header').outerHeight(),
		$quickLinks = $('nav.site-quicklinks').outerHeight(),
		$footerHeight = $('footer.site-footer').outerHeight();

		$('.menu-container .menu-contain').css({
			'padding-bottom' : $quickLinks,
			'padding-top' : $navHeight
		});

		function mediaActive(){
			$
		}
        function benefits(){
          $('.items').css('width', $('.imggal').width());
          $(window).resize(function(){
            var $img = $('.imggal').width();
            $('.items').css('width',$img);
          });
        }
        function loadmore() {
            $(function () {
                $(".media-item").slice(0, 6).show();
                $("#loadmore").on('click', function (e) {
                    e.preventDefault();
                    $(".media-item:hidden").slice(0, 6).slideDown();
                    if ($(".media-item:hidden").length == 0) {
                        $("#loadmore").fadeOut('slow');
                    }
                    // $('html,body').animate({
                    //     scrollTop: $(this).offset().top
                    // }, 1500);
                });
            });

            $('a[href=#top]').click(function () {
                $('body,html').animate({
                    scrollTop: 0
                }, 600);
                return false;
            });

            $(window).scroll(function () {
                if ($(this).scrollTop() > 50) {
                    $('.totop a').fadeIn();
                } else {
                    $('.totop a').fadeOut();
                }
            });
        }
        function lightcas(){
            if($('.whoweare').is(':visible')){
                $('.items').click(function(){ 
                    setTimeout(function(){
                        $('#lightcase-case').addClass('whoweareactive');
                        $('#lightcase-nav').addClass('whoweareactivenav');
                        $('.lightcase-contentInner').addClass('noBorder');
                        $('.slick-slider-image').addClass('benImage');
                    }, 200); 
                });
            }else{
                $('#lightcase-case').removeClass('whoweareactive');
            }
        }
        function hovering(){
            $('.inside').each(function(){
                $(this).hover(function(){
                    $(this).find('.items').css('opacity','1');
                    var $img = $('.imggal').width();
                    $('.items').css('width',$img);
                },function(){
                    $(this).find('.items').css('opacity','0');
                }
                );
            });
        }
        function slickSlider(){
            if($('.benefits_rep').is(':visible')) {
                 var $slideshow = $('.benefits_rep').slick({
                  autoplay: false,
                  infinite: true,
                  speed: 300,
                  slidesToShow: 3,
                  slidesToScroll: 1,
                  arrows: true,
                  dots: false,
                  showSequenceInfo: false,
                  prevArrow : '<button type="button" class="slick-prev slick-controller fa fa-chevron-left"></button>',
                  nextArrow : '<button type="button" class="slick-next slick-controller fa fa-chevron-right"></button>',
                  responsive: [
                    {
                      breakpoint: 1200,
                      settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1
                      }
                    },
                    {
                      breakpoint: 768,
                      settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                      }
                    }
                    // You can unslick at a given breakpoint now by adding:
                    // settings: "unslick"
                    // instead of a settings object
                  ]
                }); 
                $('.slideNext').click(function() {
                    $slideshow.slick('slickNext');
                });
                $('.slidePrev').click(function() {
                    $slideshow.slick('slickPrev');
                });
            }
        }

        function slickSlider2(){
            if($('.gen_rep').is(':visible')) {
                 var $slideshow = $('.gen_rep').slick({
                  autoplay: true,
                  infinite: true,
                  speed: 300,
                  slidesToShow: 1,
                  slidesToScroll: 1,
                  arrows: true,
                  dots: true,
                  prevArrow : '<button type="button" class="slick-prev slick-controller fa fa-chevron-left"></button>',
                  nextArrow : '<button type="button" class="slick-next slick-controller fa fa-chevron-right"></button>',
                  responsive: [
                    {
                      breakpoint: 1200,
                      settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                      }
                    },
                    {
                      breakpoint: 768,
                      settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                      }
                    }
                    // You can unslick at a given breakpoint now by adding:
                    // settings: "unslick"
                    // instead of a settings object
                  ]
                }); 
                $('.slideNext').click(function() {
                    $slideshow.slick('slickNext');
                });
                $('.slidePrev').click(function() {
                    $slideshow.slick('slickPrev');
                });
            }
        }

		function what_we_do_slider() {
			if($('.slides-wwd').is(':visible')) {
	            var $slideshow = $('.slides-wwd').slick({
                  autoplay: false,
                  infinite: true,
                  speed: 300,
                  slidesToShow: 4,
                  slidesToScroll: 1,
                  arrows: true,
                  dots: true,
                  prevArrow : '<button type="button" class="slick-prev slick-controller fa fa-chevron-left"></button>',
                  nextArrow : '<button type="button" class="slick-next slick-controller fa fa-chevron-right"></button>',
				  responsive: [
				    {
				      breakpoint: 992,
				      settings: {
				        slidesToShow: 3,
				        slidesToScroll: 1,
				        infinite: true,
				      }
				    },
				    {
				      breakpoint: 768,
				      settings: {
				        slidesToShow: 2,
				        slidesToScroll: 1,
				      }
				    },
				    {
				      breakpoint: 576,
				      settings: {
				        slidesToShow: 1,
				        slidesToScroll: 1,
				      }
				    }
				    // You can unslick at a given breakpoint now by adding:
				    // settings: "unslick"
				    // instead of a settings object
				  ]
				});
                $('.slideNext').click(function() {
                    $slideshow.slick('slickNext');
                });
                $('.slidePrev').click(function() {
                    $slideshow.slick('slickPrev');
                });
			}
		}

		function related_news_slider() {
			if($('.rel-news-slider').is(':visible')) {
	            var $slideshow = $('.rel-news-slider').slick({
                  autoplay: true,
                  infinite: true,
                  speed: 300,
                  slidesToShow: 3,
                  slidesToScroll: 1,
                  arrows: true,
                  dots: false,
                  prevArrow : '<button type="button" class="slick-prev slick-controller fa fa-chevron-left"></button>',
                  nextArrow : '<button type="button" class="slick-next slick-controller fa fa-chevron-right"></button>',
				  responsive: [
				    {
				      breakpoint: 992,
				      settings: {
				        slidesToShow: 3,
				        slidesToScroll: 1,
				        infinite: true,
				      }
				    },
				    {
				      breakpoint: 768,
				      settings: {
				        slidesToShow: 2,
				        slidesToScroll: 1,
				      }
				    },
				    {
				      breakpoint: 576,
				      settings: {
				        slidesToShow: 1,
				        slidesToScroll: 1,
				      }
				    }
				    // You can unslick at a given breakpoint now by adding:
				    // settings: "unslick"
				    // instead of a settings object
				  ]
				});
                $('.slideNext').click(function() {
                    $slideshow.slick('slickNext');
                });
                $('.slidePrev').click(function() {
                    $slideshow.slick('slickPrev');
                });
			}
		}

		$(document).on('click', '.mobile-menu.notAnimated', function(){
			$('body, html').css({
				'overflow' : 'hidden'
			});

			$(this).removeClass('notAnimated').addClass('animated');
			$('.menu-container').addClass('show');
			setTimeout(function(){
				$('.menu-container').addClass('animate');
			}, 500);
		});

		$(document).on('click', '.mobile-menu.animated', function(){
			$(this).removeClass('animated').addClass('notAnimated');

			$('.menu-container').removeClass('animate');
			$('body, html').removeAttr('style');

			setTimeout(function(){
				$('.menu-container').removeClass('show');
			}, 500);

			setTimeout(function(){
				$('.menu-container .menu-contain nav ul li .sub-menu').removeClass('show');
			}, 700);

		});

		$(document).on('click', '.submenu-button', function(){
			$(this).parent('li').find(' > .sub-menu').addClass('show');
		});
		$(document).on('click', '.submenu-close-button', function(){
			$(this).parent('li').parent('ul').removeClass('show');
		});

		$(document).on('click', 'a[href^="mailto:"].main', function(e){
			e.preventDefault();
			$('body, html').css({
				'overflow' : 'hidden'
			});

			$('.mailto-container').addClass('show');
			setTimeout(function(){
				$('.mailto-container').addClass('animate');
			}, 300);
		});

		$(document).on('click', '.mailto-close-button', function(){
			$('.mailto-container').removeClass('animate');
			$('body, html').removeAttr('style');

			setTimeout(function(){
				$('.mailto-container').removeClass('show');
			}, 300);
		});

		$(document).on('click', '.search-btn', function(){
			$('body, html').css({
				'overflow' : 'hidden'
			});
			$('.search-form-template').addClass('show');
			setTimeout(function(){
				$('.search-form-template').addClass('animate');
			}, 300);
		});

		$(document).on('click', '.search-close-button', function(){
			$('body, html').removeAttr('style');
			$('.search-form-template').removeClass('show');
			setTimeout(function(){
				$('.search-form-template').removeClass('animate');
			}, 300);
		});

		$(document).on('click', '.testimonial-item-contain', function(){
			$.ajax({
				url : $('.ajaxlocation').data('ajaxlocation'),
				type : 'POST',
				dataType: 'json',
				data : {
					action : 'request_testimonial',
					id : $(this).data('id')
				},
				beforeSend: function(){
					$('body, html').css({
						'overflow' : 'hidden'
					});
					$('.popup-modal-container').addClass('show');
					$('html').addClass('popupshow');
				},
				success: function(response) {
					
					setTimeout(function(){
						$('.popup-modal-container').addClass('animate');
					}, 300);
					$('.popup-modal-container .popup-body').html(response.content);
			
				}
			});
		});

		$(document).on('click', '.popup-close', function(){
			$('body, html').removeAttr('style');
			$('header.site-header.push-top').removeAttr('style');
			$('.popup-modal-container').removeClass('show');
			$('html').removeClass('popupshow');
			setTimeout(function(){
				$('.popup-modal-container').removeClass('animate');
			}, 300);
		});


		$(document).on('click', '.apply-btn', function(e){
			var position = $(this).data('position');
			e.preventDefault();
			$('body, html').css({
				'overflow' : 'hidden'
			});

			$('.career-container .yourPosition input').val(position);
			$('.career-container').addClass('show');
			setTimeout(function(){
				$('.career-container').addClass('animate');
			}, 300);
		});

		$(document).on('click', '.career-close-button', function(){
			$('.career-container').removeClass('animate');
			$('body, html').removeAttr('style');

			setTimeout(function(){
				$('.career-container').removeClass('show');
			}, 300);
		});

		$(document).on('click', '#resumeBtn', function(){
			var input = $('.resume-container .resume input');
			input.click();
		});

		$('.resume-container .resume input').change(function(e){
			var FileName = e.target.files[0];
			$('#resumeBtn').addClass('active').text(FileName.name);
		});

		$(document).on('click', '.expand-term', function(e){
			e.preventDefault();
			$(this).toggleClass('fa-angle-down').toggleClass('fa-angle-up');
			$(this).parent('a').parent('.parent-term').find('.chid-term').first().toggleClass('active');
		});

		$(document).on('click', '.expand-selection-term', function(e){
			$('.archive-sidebar .term-container').toggleClass('active');
		});

		$(document).on('click', '.parent-backdrop .backdrop', function(){
			$('body, html').removeAttr('style');
			$(this).parent('.parent-backdrop').removeClass('show');
			setTimeout(function(){
				$(this).parent('.parent-backdrop').removeClass('animate');
			}, 300);
		});

	});
});